module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Urban Care Chiropractic","short_name":"Urban Care Chiropractic","description":"Urban Care Chiropractic provides chiropractic services, physical therapy and massage therapy in the West Loop of Chicago. Schedule your consultation with Dr. Kenny Snook at 312-841-5500.","lang":"en","start_url":"/","background_color":"#129fb5","theme_color":"#129fb5","display":"standalone","icon":"src/assets/images/favicon.png","icons":[{"src":"src/assets/images/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c59bcde5c89cc7d92c7bec164bebd9de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
