import React from "react"
import Img from "gatsby-image"
import RBCarousel from "react-bootstrap-carousel"
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css"

export default React.memo(function Hero({ pictures }) {
  const positionX = image => `${image.hotspot.x * 100}%`
  const positionY = image => `${image.hotspot.y * 100}%`

  return (
    <>
      <section className="container-fluid m-0 p-0" id="hero">
        <RBCarousel
          className="carousel-fade"
          version={4}
          leftIcon={
            <>
              <span className="carousel-control-prev-icon" />
              <span className="visually-hidden">Previous</span>
            </>
          }
          rightIcon={
            <>
              <span className="carousel-control-next-icon" />
              <span className="visually-hidden">Next</span>
            </>
          }
        >
          {pictures.map((pic, i) => {
            return (
              <div
                className="d-block w-100 carousel-img-container"
                key={i}
                style={{
                  minHeight: "618px",
                  overflow: "hidden",
                }}
              >
                <Img
                  fluid={pic.image.asset.fluid}
                  style={{ maxHeight: "100%", minHeight: "618px" }}
                  imgStyle={{
                    objectPosition: `${positionX(pic.image)} ${positionY(
                      pic.image
                    )}`,
                  }}
                />

                <div
                  style={{
                    color: `${pic.isTextBlack && "black"}`,
                  }}
                  className="carousel-caption d-none d-sm-block"
                >
                  <h1 className="fs-5">{pic.title}</h1>
                  <h2 className="fs-6 fw-normal">{pic.subtitle}</h2>
                </div>
              </div>
            )
          })}
        </RBCarousel>
      </section>
    </>
  )
})
