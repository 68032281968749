import React from "react"
import { Link } from "gatsby"

import book from "../assets/images/icons/book.svg"
import clock from "../assets/images/icons/clock.svg"
import location from "../assets/images/icons/geo-alt-fill.svg"
import telephone from "../assets/images/icons/telephone-fill.svg"

export default React.memo(function Infobar() {
  return (
    <section className="container my-5 px-3">
      <div className="row justify-content-center align-items-stretch g-3 gx-lg-0">
        <h1 className="visually-hidden">General information</h1>
        <div className="col-10 col-md-6 col-lg-3 d-flex justify-content-center">
          <div className="card rounded-0 hero__card" style={{ width: "100%" }}>
            <div className="card-body">
              <div className="link-dark text-decoration-none">
                <div className="mb-2" style={{ height: "1.5rem" }}>
                  <img src={book} alt="Appointments" height="100%" />
                </div>
                <h2 className="card-title h5">Schedule Appointment</h2>
                <div className="schedule-btn__container">
                  <div className="schedule-btn">
                    <Link to="/new-patient#patient" className="btn btn-dark">
                      New Patient
                    </Link>
                  </div>

                  <div className="schedule-btn">
                    <a
                      href="https://urbancarechiropractic.schedulista.com"
                      target="_blank"
                      className="btn btn-dark"
                    >
                      Existing Patient
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer d-lg-none schedule-btn__footer">
              <Link to="/new-patient#patient" className="btn btn-dark">
                New Patient
              </Link>
              <a
                href="https://urbancarechiropractic.schedulista.com"
                target="_blank"
                className="btn btn-dark"
              >
                Existing Patient
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-6 col-lg-3 d-flex justify-content-center">
          <div className="card rounded-0 hero__card" style={{ width: "100%" }}>
            <div className="card-body">
              <a target="_blank" className="link-dark text-decoration-none">
                <div className="mb-2" style={{ height: "1.5rem" }}>
                  <img src={clock} alt="Business hours" height="100%" />
                </div>
                <h2 className="card-title h5">Business hours</h2>
                <h3 className="card-subtitle mb-2 text-muted h6">
                  We are open for you
                </h3>
              </a>
              <p className="card-text m-0 p-0">Tue-Thu: 11AM - 7PM</p>
              <p className="card-text m-0 p-0">Fri: 11AM - 6PM</p>
              <p className="card-text m-0 p-0">Sat: 10AM - 2PM</p>
              <p className="card-text m-0 p-0">Sun-Mon: Closed</p>
            </div>
            <div className="card-footer d-lg-none">
              <a href="#appointments" className="btn btn-dark">
                Learn more{" "}
                <span className="visually-hidden">
                  about our business hours
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-6 col-lg-3 d-flex justify-content-center">
          <div className="card rounded-0 hero__card" style={{ width: "100%" }}>
            <div className="card-body">
              <a href="#location" className="link-dark text-decoration-none">
                <div className="mb-2" style={{ height: "1.5rem" }}>
                  <img src={location} alt="Location" height="100%" />
                </div>
                <h2 className="card-title h5">Location</h2>
                <h3 className="card-subtitle mb-2 text-muted h6">
                  West Loop Chicago
                </h3>
              </a>
              <p className="card-text m-0 p-0">1335 West Lake St. #102</p>
              <p className="card-text m-0 p-0">Chicago, IL 60607</p>
            </div>
            <div className="card-footer d-lg-none">
              <a href="#location" className="btn btn-dark">
                Learn more{" "}
                <span className="visually-hidden">
                  about where we are located
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-6 col-lg-3 d-flex justify-content-center">
          <div className="card rounded-0 hero__card" style={{ width: "100%" }}>
            <div className="card-body">
              <a href="#contact" className="link-dark text-decoration-none">
                <div className="mb-2" style={{ height: "1.5rem" }}>
                  <img src={telephone} alt="Contact" height="100%" />
                </div>
                <h2 className="card-title h5">Contact</h2>
                <h3 className="card-subtitle mb-2 text-muted h6">
                  Any questions?
                </h3>
              </a>
              <p className="card-text m-0 p-0">
                <a
                  className="card-link link-dark text-decoration-none"
                  href="tel:312-841-5500"
                >
                  312-841-5500
                </a>
              </p>
              <p className="card-text m-0 p-0">
                <a
                  className="card-link link-dark text-decoration-none"
                  href="mailto:urbancarechiro@gmail.com"
                >
                  urbancarechiro@gmail.com
                </a>
              </p>
            </div>
            <div className="card-footer d-lg-none">
              <a href="#contact" className="btn btn-dark">
                Learn more
                <span className="visually-hidden">
                  about how to contact us.
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})
