import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import React from "react"
import telephone from "../assets/images/icons/telephone-fill.svg"
import logo from "../assets/images/logos/fav.png"

const urls = [
  {
    title: "Schedule Appointment",
    url: "https://urbancarechiropractic.schedulista.com",
  },
  { title: "About", url: "/#about" },
  { title: "Specialties", url: "/specialties#specialties" },
  { title: "Services", url: "/#services" },
  { title: "Our Process", url: "/new-patient#patient" },
  { title: "Location", url: "/#location" },
  { title: "Blog", url: "/#blog" },
  { title: "Contact", url: "/#contact" },
]
export default React.memo(function Nav() {
  const replaceHistory = path => {
    if (useLocation().pathname === "/" && path === "/fajas") {
      return false
    } else if (
      (useLocation().pathname === "/fajas" ||
        useLocation().pathname === "/fajas/") &&
      path !== "/fajas"
    ) {
      return false
    } else {
      return true
    }
  }
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark navbar__bg"
      id="navbar"
    >
      <div className="container-fluid">
        <div style={{ display: "flex" }}>
          <Link
            className="navbar-brand logo__container"
            to="/#"
            replace={replaceHistory("/#")}
          >
            <img src={logo} alt="" className="d-inline-block" height="4em" />
            <p className="logo--text__container">
              <span className="logo--hightlight">Urban care</span>
              <span className="logo--text">Chiropractic</span>
            </p>
          </Link>
          <a href="tel:312-841-5500" className="navbar-nav">
            <span
              itemProp="telephone"
              className="d-block my-2 px-2 py-2 nav-link"
              style={{ fontSize: "13px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                class="bi bi-telephone-fill mx-2 mb-1"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
              312-841-5500
            </span>
          </a>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {urls.map((url, i) => (
              <li className="nav-item" key={i}>
                {url.title === "Appointment Booking" ? (
                  <a
                    className="nav-link"
                    href={url.url}
                    target="_blank"
                    rel="noopener"
                  >
                    {url.title}
                  </a>
                ) : (
                  <Link
                    className="nav-link"
                    to={url.url}
                    replace={replaceHistory(url.url)}
                  >
                    {url.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
})
