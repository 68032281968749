import React, { useEffect, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/styles/styles.css"
import Nav from "./Nav"
import Blog from "./Blog"
import Hero from "./Hero"
import Infobar from "./Infobar"

import Footer from "./Footer"

export default function Layout({ children }) {
  const offset = 80
  const seconds = 0.2
  const buttonRef = useRef(null)

  const displayScrollBtn = () => {
    if (
      document.body.scrollTop > offset ||
      document.documentElement.scrollTop > offset
    ) {
      fadeIn(buttonRef.current, seconds)
    } else {
      fadeOut(buttonRef.current, seconds)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", displayScrollBtn)

    return () => {
      window.removeEventListener("scroll", displayScrollBtn)
    }
  }, [])

  const fadeIn = (element, time) => {
    element.style.animation = "fadeIn " + time + "s"
    setTimeout(() => {
      element.style.display = "block"
    }, time * 1000)
  }

  const fadeOut = (element, time) => {
    element.style.animation = "fadeOut " + time + "s"
    setTimeout(() => {
      element.style.display = "none"
    }, time * 1000)
  }

  const scrollToTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  const { posts, images } = useStaticQuery(graphql`
    query {
      posts: allSanityPost {
        nodes {
          date
          description
          id
          link
          title
          file {
            asset {
              url
            }
          }
        }
      }
      images: allSanityImages(filter: { active: { eq: true } }) {
        nodes {
          id
          title
          subtitle
          active
          isTextBlack
          image {
            asset {
              id
              url
              metadata {
                dimensions {
                  height
                  width
                }
              }
              fluid(maxWidth: 2560) {
                ...GatsbySanityImageFluid
              }
            }
            hotspot {
              y
              x
              width
              height
            }
            crop {
              bottom
              left
              right
              top
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Nav />
      <button
        type="button"
        ref={buttonRef}
        onClick={scrollToTop}
        className="btn btn-link"
        id="scroll-top"
        title="Scroll to Top"
      ></button>
      <Hero pictures={images.nodes} />
      <Infobar />
      {children}
      <Blog posts={posts.nodes} />
      <Footer />
    </>
  )
}
