import React, { useEffect } from 'react';
import moment from 'moment';
import Glider from '../assets/js/glider';

function Blog({ posts }) {
  const getViewWidth = () =>
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const getSlideCount = () => {
    const viewWidth = getViewWidth();
    if (viewWidth >= 1400) {
      return 5;
    }
    if (viewWidth >= 1200) {
      return 4;
    }
    if (viewWidth >= 992) {
      return 3;
    }
    if (viewWidth >= 768) {
      return 2;
    }
    if (viewWidth >= 576) {
      return 1;
    }
    return 1;
  };

  const updateGlider = (g) => {
    const slideCount = getSlideCount();
    g.setOption({ slidesToShow: slideCount });
    g.refresh(true);
  };

  useEffect(() => {
    const glider = new Glider(document.querySelector('#glider'), {
      slidesToShow: getSlideCount(),
      slidesToScroll: 'auto',
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '#glider-prev',
        next: '#glider-next',
      },
    });
    window.addEventListener('resize', () => updateGlider(glider));
    return () => {};
  }, []);

  return (
    <section className="container my-5" id="blog">
      <div className="row px-3 mb-3">
        <div className="col">
          <h1 className="mb-3">Blog</h1>
        </div>
        <div className="col d-flex justify-content-end">
          <button
            type="button"
            aria-label="Previous"
            className="btn btn-dark mx-2 mb-1 disabled"
            id="glider-prev"
          >
            ←
          </button>
          <button
            type="button"
            aria-label="Next"
            className="btn btn-dark mx-2 mb-1"
            id="glider-next"
          >
            →
          </button>
        </div>
      </div>
      <div className="glider row justify-content-evenly" id="glider">
        {posts
          .sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0))
          .map((post) => (
            <a
              key={post.id}
              href={post.link ? post.link : `${post.file?.asset?.url}`}
              rel="noreferrer"
              target="_blank"
              className="col-3 px-2 d-flex justify-content-center text-decoration-none text-dark"
            >
              <article
                className={`card ${post.link ? 'vlog' : ''}`}
                style={{ maxWidth: '300px' }}
              >
                <div className="card-header bg-primary text-light">
                  {post.title}
                </div>
                <div className="card-body">{post.description}</div>
                <div className="card-footer bg-white fst-normal">
                  <span className="text-muted">
                    {moment(post.date).format('MMM D, YYYY')}
                  </span>
                </div>
              </article>
            </a>
          ))}
      </div>
    </section>
  );
}

export default React.memo(Blog);
